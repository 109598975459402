#my-home2{
    width: 100vw;
    min-height: 100vh;
    clear: both;
    font-size: 24px;
    color: white;
    margin: auto;
    display: flex;
    align-items: center;
    // width: 1920px;
    .c1{
        background: url("../../imgs/c1.png");
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        // padding-top: 10vw;
        // flex: 1;
        @media (max-width:500px) {
            padding-top: 10px;
        }
        .c1_1{
            // width: 70%;
        } 
        .c1_2{
            width: 491px;
            height: 673px;
            background: url("../../imgs/loginbg.png") no-repeat;
            padding: 80px 36px;
            // background-size: cover;
            // background-position: center;
            @media (max-width:500px) {
                width: 100vw;
                height: 673px;
                background-size: 100% 100%;
            }
            .c1_2_1{
                // margin-top: 80px;
                // margin-left: 36px;
            }
            .c1_2_2{
                margin: auto;
                font-size: .24rem;
                color: white;
                transition: .3s all;
                margin-top: 28px;
                margin-bottom: 47px;
                input { 
                    background: url("../../imgs/inputbg.png") no-repeat ;
                    background-size: 100% 100%;
                    width: 100%;
                    height: 50px;
                    // border: none;
                    // font-size: .24rem;
                    font-size: 22px;
                    // outline: 0;
                    padding:  10px 20px;
                    color: white;
                    // border: 1px solid rgba(0,0,0,0);
                }
                .ant-input:focus, .ant-input-focused{
                    // border-color: #7c8f9e;
                    border: 1px solid #40a9ff;
                }
                .ant-input:hover{
                    // border-color: #7c8f9e;
                    border: 1px solid #cbe3f7;
                }
                
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-transition-delay: 111111s;
                    -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
                } 
                
                .sdsds{
                    // background: url("../../imgs/inputbg.png") no-repeat ;
                    background: transparent;
                    padding: 0 20px 0 0;
                    .ant-input-affix-wrapper{
                        // background: url("../../imgs/inputbg.png") no-repeat ;
                        
                    }
                    input{
                        background: transparent;
                    }
                    .ant-input:hover{
                        // border-color: #7c8f9e;
                        border: 0px solid #cbe3f7;
                    }
                    .ant-input:focus, .ant-input-focused{
                        // border-color: #7c8f9e;
                        border: 0px solid #40a9ff;
                    }
                }
            }
            .c1_2_3{
                display: flex;
                justify-content: space-around;
                .ant-checkbox-wrapper{
                    color: #4A97E9 !important;
                    font-size: 20px;
                    font-weight: 600;
                }
                .ant-checkbox-inner{
                    // background: #40a9ff !important;
                    background: url("../../imgs/check.png") no-repeat 100% 100%;
                    width: 23px;
                    height: 23px;
                    border: none;
                }
                .ant-checkbox-checked .ant-checkbox-inner{
                    background: url("../../imgs/checked.png") no-repeat 100% 100%;
                    width: 27px;
                    height: 23px;
                }
                .ant-checkbox-checked .ant-checkbox-inner::after{
                    opacity:0;
                }
            }
            .c1_2_4{
                display: flex;
                justify-content: center;
                margin-top: 44px;
                .c1_2_4_1{
                    background: url("../../imgs/button.png") no-repeat 100% 100%;
                    width: 302px;
                    height: 90px;
                    border: none;
                    transition: all .5s;
                }
                .c1_2_4_1:hover{
                    background: url("../../imgs/button2.png") no-repeat ;
                    background-position: center;
                    background-size: 100% 100%;
                    // width: 330px;
                    // height: 113px;
                }
            }
        }
    }
}