@import '~antd/dist/antd.css';

.App {
  box-sizing: border-box;
  min-height: 100vh;
  // height: 100vh;
  // background: url('./assets/images/yuan/back.png') no-repeat;
  background: url('../src/imgs/back2.jpg');
  background-size: cover;
  background-position:center;
  // width: 1920px;
  // font-family:"Microsoft YaHei"!important;
  background-repeat: repeat;
  min-width: 1900px;
}

.app-container {
  height: 100%;
  width: 100%;
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: RGBA(12, 7, 29, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}